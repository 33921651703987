import React, { useState } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { doc, setDoc } from 'firebase/firestore/lite';
import { auth, userCollectionRef } from '../../services/firebase-config';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../context/auth-context';
import { object, string, ref } from 'yup';
import MyModal from '../../components/popup/modal';


export default function SignUpPage() {

    const [showModal, setShowModal] = useState(false);

    const [modalContent, setModalContent] = useState({ title: "Hata", message: "" })

    const [email, setEmail] = useState("");

    const [password, setPassword] = useState("");

    const [checkPassword, setCheckPassword] = useState("");

    const schema = object({
        email: string()
            .required('Email alanı boş bırakılamaz')
            .email('Geçerli bir email adresi girin, örn: user@example.com'),
        password: string()
            .required('Parola gerekli')
            .min(8, 'Parola en az 8 karakter olmalı')
            .notOneOf([ref('email')], 'Parola e-posta adresiyle aynı olamaz'),
        checkPassword: string()
            .oneOf([ref('password')], 'Parolalar eşleşmiyor')
            .required('Parolayı tekrar girmeniz gerekiyor')
            .min(8, 'Parola en az 8 karakter olmalı')
            .max(254)
    });

    const { user, setUser } = useAuth();

    const navigate = useNavigate();

    const handleEmailChange = (event) => { setEmail(event.target.value); }

    const handlePasswordChange = (event) => { setPassword(event.target.value); }

    const handleCheckPasswordChange = (event) => { setCheckPassword(event.target.value); }

    const handleSubmit = async (event) => {

        event.preventDefault();

        await schema.validate({ email, password, checkPassword }).then(async () => {

            await createUserWithEmailAndPassword(auth, email, password).then(async (userCredential) => {

                setUser(userCredential.user);

                const userDocRef = doc(userCollectionRef, userCredential.user.uid);

                await setDoc(userDocRef, { uid: userCredential.user.uid }).then(() => {

                    sendEmailVerification(userCredential.user).then(() => {

                        navigate("/verify-email");

                    }).catch((error) => { setShowModal(!showModal); setModalContent({ title: "Hata", message: error.message }) });

                }).catch((error) => { throw new Error(error.message); });

            }).catch((error) => { setShowModal(!showModal); setModalContent({ title: "Hata", message: error.message }) });

        }).catch((error) => { setShowModal(!showModal); setModalContent({ title: "Hata", message: error.message }) });

    }

    return (

        user ?

            <section className="sr-layout p-4 items-center justify-center">
                <div className="max-w-md w-full px-6 py-8 bg-blue rounded-lg ">
                    <h1 className="text-2xl font-semibold text-center">Hoş Geldin.</h1>
                    <p className="text-center">Giriş yapmış durumdasınız.</p>
                    <button onClick={() => navigate("/")} className="bg-blue-500 w-full text-blue p-3 mt-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Anasayfa</button>
                    <button onClick={() => setUser(null)} className="bg-red-500 w-full text-blue p-3 mt-4 rounded-lg hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-200">Çıkış yap</button>
                </div>
            </section>

            :

            <div className="sr-layout p-4 items-center justify-center">
                <h1 className="text-2xl font-semibold text-center">Kayıt Ol</h1>
                <div className="max-w-md w-full px-6 py-8 bg-blue rounded-lg ">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <input
                                type="email"
                                id="email"
                                placeholder="Email"
                                autoComplete='email'
                                className="mt-1 px-4 py-4 w-full border rounded-lg"
                                onChange={handleEmailChange}
                            />
                        </div>
                        <div className="mb-4">
                            <input
                                type="password"
                                id="password"
                                placeholder="Password"
                                autoComplete='password'
                                className="mt-1 px-4 py-4 w-full border rounded-lg"
                                onChange={handlePasswordChange}
                            />
                        </div>
                        <div className="mb-4">
                            <input
                                type="password"
                                id="check-password"
                                placeholder="Password"
                                autoComplete='password'
                                className="mt-1 px-4 py-4 w-full border rounded-lg"
                                onChange={handleCheckPasswordChange}
                            />
                        </div>
                        <div className="flex flex-row justify-between my-4">
                            <button
                                type="submit"
                                className=" w-full text-white p-3 mr-2 rounded-lg bg-green-600 hover:bg-green-700 focus:outline-none focus:ring focus:ring-blue-200"
                            >
                                kayıt ol

                            </button>

                            {showModal && <MyModal content={modalContent} />}

                            <button
                                onClick={() => { navigate("/login"); }}
                                type="button"
                                className="bg-blue-500 w-full text-white p-3 ml-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                            >
                                <span className="underline">giriş yap</span>
                            </button>

                        </div>
                    </form>
                </div>
            </div>
    );
}