import React, { useEffect, useState } from "react";
import Post from "../post/get-post";
import { getAllPost } from "../../services/post-methods";
import LoadingComponent from "../../components/loading";
import { useAuth } from "../../context/auth-context";

export default function Home() {

	const authContext = useAuth();

	const [posts, setPosts] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
	

	useEffect(() => {
        
        (async () => {

			setIsLoading(true);

			authContext.user && await getAllPost().then((data) => { setPosts(data);  setIsLoading(false); })

		})();

	}, [authContext.user]);

	return (
		<section className="sr-layout">
			<div className="sr-layout flex-col w-full px-2">
				{
                    isLoading ? <LoadingComponent /> :
					<ul className="my-1 p-0">
						{posts.map((post) => (<Post key={post.docId} post={post} />))}
					</ul>
				}
			</div>
		</section>
	);
}
