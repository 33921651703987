import React, { useState, useRef, useEffect } from "react";
import { FaEllipsisV } from "react-icons/fa";
import PropTypes from "prop-types";
import { deleteDocument, updateDocument } from "../../../services/post-methods";
import LoadingComponent from "../../../components/loading/";
import { useNavigate } from "react-router";
import { useAuth } from "../../../context/auth-context";
import { getUserDataByUid } from "../../../services/user-methods";
import { BsHeart } from "react-icons/bs";
import { Link } from "react-router-dom";



const PostOptionsMenu = ({ post }) => {

    const authContext = useAuth();

    const navigate = useNavigate();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuRef = useRef(null);

    const hasSet = authContext.user?.uid ? authContext.user?.uid === post.uid : false;

    const toggleMenu = () => { setIsMenuOpen(!isMenuOpen); };

    const handleClickOutside = (event) => { if (menuRef.current && !menuRef.current.contains(event.target)) { setIsMenuOpen(false); } };

    const handleMenuClick = () => { setIsMenuOpen(false); };

    const handleDeleteClick = () => { deleteDocument(post.docId); localStorage.removeItem("posts"); }

    const handleEditClick = () => { navigate("/set-post", { state: { post } }); };


    useEffect(() => {

        document.addEventListener("mousedown", handleClickOutside);

        return () => { document.removeEventListener("mousedown", handleClickOutside); };

    }, [authContext.user.uid, post.like_users, post.uid]);

    return (
        <div ref={menuRef} className="relative text-left flex items-center">

            <button type="button" className="text-gray-700 px-4 py-2 focus:outline-none" onClick={toggleMenu} >

                <FaEllipsisV />

            </button>

            {isMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg py-1">

                    {hasSet ? (

                        <React.Fragment>
                            <button
                                className="flex w-full px-4 py-2 text-gray-800 hover:bg-gray-200"
                                onClick={() => { handleEditClick(); handleMenuClick(); }}
                            >
                                Düzenle
                            </button>
                            <button
                                className="flex w-full px-4 py-2 text-gray-800 hover:bg-gray-200"
                                onClick={() => { handleDeleteClick(); handleMenuClick(); }}
                            >
                                Sil
                            </button>

                        </React.Fragment>

                    ) : (
                        <button
                            className="flex w-full px-4 py-2 text-gray-800 hover:bg-gray-200"
                            onClick={handleMenuClick}
                        >
                            şikayet et
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

const Post = ({ post }) => {

    const postRef = useRef(null);

    const authContext = useAuth();

    const [isLoading, setIsLoading] = useState(true)

    const [postUser, setPostUser] = useState(null);

    const [isLiked, setIsLiked] = useState(false);

    const [like_users, setlike_users] = useState([...post.like_users]);


    const handleLike = async () => {

        const updatedLikeUsers = isLiked ? like_users?.filter((user) => user !== authContext.user.uid) : [...like_users, authContext.user.uid];

        setlike_users(updatedLikeUsers);

        await updateDocument(post.docId, { ...post, like_users: updatedLikeUsers });

        setIsLiked(!isLiked);

    };

    useEffect(() => {

        post.like_users?.includes(authContext.user.uid) ? setIsLiked(true) : setIsLiked(false);

        (async () => {

            setIsLoading(true);

            await getUserDataByUid(post.uid).then(async (response) => {

                setPostUser(response);

                setIsLoading(false);

            }).catch((error) => { throw new Error(error.message); });

        })();

    }, [authContext.user.uid, post.like_users, post.uid]);

    return (

        isLoading ? <LoadingComponent/> :

            <article ref={postRef} className="post my-2 flex flex-col bg-gray-100 rounded-md ">

                <header className="flex justify-between items-center p-1 rounded-tr-md rounded-tl-md bg-gray-200 text-gray-800">
                    <div className="post-info flex items-center">
                        <Link to={`/${postUser?.username}`} className="flex items-center">
                            <img
                                src={postUser?.photoURL || "https://www.gravatar.com/avatar/0?d=mp"}
                                alt="profile"
                                className="w-10 h-10 rounded-full mr-2"
                            />
                        </Link>
                        <span className="text-gray-900 text-sm">
                            {postUser?.displayName}
                            <br />
                        </span>
                    </div>

                    <div className="menu flex items-center justify-center center">
                        <PostOptionsMenu post={post} />
                    </div>
                </header>

                <section className="">
                    <span className="flex px-2 py-6 bg-gray-100">{post?.content}</span>
                </section>



                <footer className="flex justify-between items-center rounded-br-md rounded-bl-md bg-gray-200">

                    <div className="post-info flex items-center">


                        <button className="flex items-center text-gray-400 text-gray p-4 rounded-md">
                        </button>

                    </div>

                    <div className="menu flex self-end items-center text-gray-400">

                        <button onClick={handleLike} type="submit" className="flex flex-row items-center text-gray-400 text-gray p-4 rounded-md">
                            <span className="font-light text-xl">{like_users?.length}</span>
                            <BsHeart className={`font-bold text-2xl pl-2 ${isLiked ? 'text-red-500' : 'text-black-500'}`} />
                        </button>

                    </div>

                </footer>

            </article>
    );
};

Post.propTypes = {
    post: PropTypes.shape({
        content: PropTypes.string.isRequired,
        timestamp: PropTypes.object.isRequired,
        like_count: PropTypes.number.isRequired,
    }).isRequired,
};

export default Post;
