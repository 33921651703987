import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/auth-context';


function useAuthRedirect() {

	const authContext = useAuth();

	const location = useLocation();

	const navigate = useNavigate();

	useEffect(() => {

		if (authContext.isLoading) { return; }

		if (authContext.user) {
			
			if (!authContext.user.emailVerified) { navigate("/check-email"); return; }
			
			else if (!authContext.userData.username) { navigate("/set-profile"); return; }
		
		} else { navigate("/login"); return; }

	}, [authContext, navigate, location.pathname]);
}

export default useAuthRedirect;
