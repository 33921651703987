// Login.js
import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { auth } from "../../services/firebase-config";
import MyModal from "../../components/popup/modal";
import * as Yup from "yup";

export default function Login() {
    
    const navigate = useNavigate();
    
    const authContext = useAuth();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({title: "Hata", message: "Bir Hata oluştu", });

    const handleEmailChange = (event) => { setEmail(event.target.value); };
    const handlePasswordChange = (event) => { setPassword(event.target.value); };

    const loginSchema = Yup.object().shape({
        
        email: Yup.string().email("Geçerli bir email adresi girin").required("Email gerekli"),        
        password: Yup.string().required("Parola gerekli").min(6, "Parola en az 6 karakter olmalı"),

    });

    const handleSubmit = async (event) => {
        
        event.preventDefault();

        try {
            // Yup şeması ile doğrulama
            await loginSchema.validate({ email, password }, { abortEarly: false });

            // Doğrulama başarılıysa giriş işlemi yapılır
            await signInWithEmailAndPassword(auth, email, password)

            .then(() => { navigate("/"); })
            
            .catch((error) => { setModalContent({ title: "Hata", message: error.message }); setShowModal(true);});
        
        } catch (validationErrors) {
            // Hata varsa modal içerik güncellenir
            const errorMessages = validationErrors.inner
                .map((err) => err.message)
                .join(", ");
            setModalContent({ title: "Hata", message: errorMessages });
            setShowModal(true);
        }
    };

    return authContext.user ? (
        <section className="sr-layout p-4 items-center justify-center">
            <div className="max-w-md w-full px-6 py-8 bg-white rounded-lg">
                <h1 className="text-2xl font-semibold text-center">Hoş Geldin.</h1>
                <p className="text-center">Giriş yapmış durumdasınız.</p>
                <button
                    onClick={() => navigate("/")}
                    className="bg-blue-500 w-full text-white p-3 mt-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                >
                    Anasayfa
                </button>
                <button
                    onClick={() => { authContext.handleLogout(); } }
                    className="bg-red-500 w-full text-white p-3 mt-4 rounded-lg hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-200"
                >
                    Çıkış yap
                </button>
            </div>
        </section>
    ) : (
        <div className="sr-layout p-4 items-center justify-center">
            <h1 className="text-2xl font-semibold text-center">Giriş Yap</h1>
            <div className="max-w-md w-full px-6 py-8 bg-white rounded-lg">
                <form className="" onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <input
                            type="email"
                            id="email"
                            placeholder="Email"
                            autoComplete="email"
                            className={`mt-1 px-4 py-4 w-full border rounded-lg ${password.length > 0 ? "border-green-500" : "border-red-500"
                                }`}
                            onChange={handleEmailChange}
                            value={email}
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            placeholder="Password"
                            className={`mt-1 px-4 py-4 w-full border rounded-lg ${password.length > 0 ? "border-green-500" : "border-red-500"
                                }`}
                            onChange={handlePasswordChange}
                            value={password}
                        />
                    </div>
                    <div className="flex flex-row justify-between my-4">
                        <button
                            type="submit"
                            className="w-full text-white p-3 mr-2 rounded-lg bg-green-500 hover:bg-green-700 focus:outline-none focus:ring focus:ring-blue-200"
                        >
                            Giriş Yap
                        </button>

                        {showModal && <MyModal content={modalContent} />}

                        <button
                            onClick={() => {
                                navigate("/signup");
                            }}
                            type="button"
                            className="bg-blue-500 w-full text-white p-3 ml-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                        >
                            <span className="underline">Kayıt Ol</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
