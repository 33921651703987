import { userCollectionRef } from "./firebase-config";

import { doc, getDoc, getDocs, query, where, addDoc, updateDoc, setDoc } from "firebase/firestore/lite";


// Get user by username with localStorage caching
export async function getUserByUsername(username) {

	if (username === "undefined") { throw new Error("Username is undefined"); }

	// Öncelikle localStorage'da "users" dizisinin olup olmadığını kontrol et
	const cachedUsers = JSON.parse(localStorage.getItem("users")) || [];

	// Eğer "users" dizisi doluysa, burada username'e göre kullanıcıyı bulmaya çalış
	const cachedUser = cachedUsers.find(user => user.username === username);

	if (cachedUser) { return cachedUser; }

	try {
		const querySnapshot = await getDoc(query(userCollectionRef, where("username", "==", username)));

		if (querySnapshot.empty) { throw new Error("User not found"); }

		// Kullanıcı Firestore'da bulundu, ilk sonucu al ve "users" dizisine ekle
		const userDataFromDb = querySnapshot.docs[0].data();

		cachedUsers.push(userDataFromDb);

		// Güncellenmiş users dizisini yeniden kaydet
		localStorage.setItem("users", JSON.stringify(cachedUsers));
		
		return userDataFromDb;

	}

	catch (error) { throw new Error(error.message); }
}

// get all documents from user collection
export async function getAllUsers() {

	const users = JSON.parse(localStorage.getItem("users")) || [];

	if (users.length > 1) {

		const users = JSON.parse(localStorage.getItem("users"));

		return users;

	} else {

		const querySnapshot = await getDocs(userCollectionRef);

		const users = querySnapshot.docs.map((doc) => doc.data());

		localStorage.setItem("users", JSON.stringify(users));

		return users;
	}
}


// Get all documents ids from message collection

export async function getUserDataByUid(uid) {

	const users = JSON.parse(localStorage.getItem("users")) || [];

	const userDataFromLocalStorage = users.find(user => user.uid === uid) || null;

	if (userDataFromLocalStorage) { return userDataFromLocalStorage; }

	try {

		const userDocRef = doc(userCollectionRef, uid);

		const userDocSnap = await getDoc(userDocRef);

		const userDataFromDb = userDocSnap.data();

		if (!users.find(user => user.uid === uid)) {

			users.push({ uid, ...userDataFromDb });

			localStorage.setItem("users", JSON.stringify(users));

		}

		return userDataFromDb;

	} catch (error) {

		return error;

	}

}


// Get document data by doc reference with localStorage caching
export async function getDocumentsByDocRef(docRef) {
    // Tüm kullanıcı verilerini al
    const cachedUsers = JSON.parse(localStorage.getItem("users")) || [];

    // docRef'e göre kullanıcıyı bul (örneğin `uid` değerini docRef ile eşleştirerek)
    const cachedUser = cachedUsers.find(user => user.uid === docRef.id);

    if (cachedUser) { return cachedUser; }

    // Kullanıcı bulunamazsa Firestore’dan al ve users dizisine ekle
    try {
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            throw new Error("Document not found");
        }

        // Firestore’dan alınan kullanıcı verisini users dizisine ekle
        const userDataFromDb = { uid: docRef.id, ...docSnap.data() };
        cachedUsers.push(userDataFromDb);

        // Güncellenmiş users dizisini localStorage'a yeniden kaydet
        localStorage.setItem("users", JSON.stringify(cachedUsers));
		
		return userDataFromDb;

    } catch (error) { throw new Error(error.message); }
}

// insert a document to user collection

export async function insertUserDocument(data) {

	localStorage.clear();

	let docId = null;

	try {

		const docRef = await addDoc(userCollectionRef, data);

		docId = docRef.id;

	} catch (error) {

		return error.message;

	} finally { return docId; }
}

// set a document to user collection

export async function setUserDocument(docId, payload) {

	await setDoc(doc(userCollectionRef, docId), payload, { merge: true })

		.then((response) => { return response; })

		.catch((error) => { return error.message; });

}

// update a document to user collection
export async function updateUserDocument(docId, payload) {

	localStorage.clear();

	await updateDoc(doc(userCollectionRef, docId), payload)

		.then((response) => { return response; })

		.catch((error) => { return error.message; });

}






