import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserByUsername } from "../../services/user-methods";
import Post from "../post/get-post";
import { getAllPost } from "../../services/post-methods";
import LoadingComponent from "../../components/loading";
import { useAuth } from "../../context/auth-context";
import { useNavigate } from "react-router";
import { usePathContext } from "../../context/path-context";

export default function UserProfile() {
    const { username } = useParams();

    const { setPathName } = usePathContext();

    const authContext = useAuth();

    const navigate = useNavigate();

    const [userProfile, setUserProfile] = useState({});

    const [usersPost, setUsersPost] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        setIsLoading(true);

        if (!username === "undefined") { throw new Error("username is undefined"); }


        if (username === authContext.user.username) { navigate("/profile"); return; }

        (async () => {

            try {

            getUserByUsername(username).then(async (data) => {

                setUserProfile(data);

                setPathName(data.displayName);

                await getAllPost().then((posts) => { setUsersPost(posts.filter((post) => post.username === username)); setIsLoading(false); });

            })

            } catch (error) { throw new Error(error.message); }

        })();

    }, [username, authContext.user, navigate, setPathName]);

    return isLoading ? (
        <LoadingComponent />
    ) : (
        <section className="sr-layout">
            <div className="sticky top-0 home-chart h-full border-b-2">
                <div className="header"></div>

                <div className="profile">
                    <img
                        src={
                            userProfile.photoUrl || "https://www.gravatar.com/avatar/0?d=mp"
                        }
                        alt="Sarıköy Gençliği"
                    />
                </div>

                <div className="content my-0">
                    <h3 className="title">{userProfile?.displayName}</h3>
                    <p>{userProfile?.bio}</p>
                </div>
            </div>

            <div className="sticky flex-col px-2 bg-white">
                <ul className="space-y-2 m-0 p-0">
                    {usersPost &&
                        usersPost.map((post) => <Post key={post.docId} post={post} />)}
                </ul>
            </div>
        </section>
    );
}
